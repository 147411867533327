.wrap {
   display: flex;
   flex-direction: row;
   justify-content: center;
   padding: 3px;
   background-color: rgba(33, 33, 33, 1);
   border-radius: 40px;
   margin: auto;
   max-width: 380px;
   box-shadow: 0 0 20px 1px rgba(5, 5, 5, 0.1);
}

.btn {
   margin-left: 8px;
   display: inline-block;
   margin: 5px 12px;
   text-align: center;
}
.btn img {
   width: calc(32px + 2vmin);
   vertical-align: bottom;
   border-radius: 50%;
}

.btn div {
   text-align: center;
   font-size: 0.9rem;
   white-space: nowrap;
}
