.wrap {
   height: 60px;
   border: 1px solid;
   position: relative;
}

.progress {
   height: 100%;
   background-image: linear-gradient(90deg, #2c69d1 0, #0abcf9 51%, #2c69d1);
   text-align: center;
   border: none;
   transition: 0.5s;
   background-size: 200% auto;
}
.content {
   position: absolute;
   width: 100%;
   left: 0;
   top: 50%;
   transform: translateY(-50%);
   background-color: transparent;
   text-align: center;
   font-size: 1.2rem;
}
