* {
   box-sizing: border-box;
}

body {
   margin: 0;
   font-family: system-ui, -apple-system,
      /* Firefox supports this but not yet `system-ui` */ "Segoe UI", Roboto,
      Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
   padding: 0;
   margin: 0;
   font-size: calc(12px + 1vmin);

   background-image: url(../public/bg.jpg);
   background-size: cover;
   background-position: center;
   background-repeat: no-repeat;
   background-attachment: fixed;
   color: rgba(224, 224, 224, 1);
}

code {
   font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
      monospace;
}
img {
   vertical-align: middle;
}

.center {
   text-align: center;
}

a {
   color: #fff;
}
a:visited {
   color: #fff;
}
