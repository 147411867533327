.wrap {
   border-radius: 20px;
}
.tools {
   display: flex;
   justify-content: space-around;
}
.item {
   margin-bottom: 20px;
}
.item div {
   text-align: center;
}

.label {
   font-weight: 600;
}

.value {
   color: #d55408;
   font-size: calc(24px + 2vmin);
}
