.wrap {
   display: flex;
   justify-content: center;
   margin-top: 20px;
   flex-direction: row;
   align-items: center;
}

.logo img {
   max-width: calc(128px + 4vmin);
}

.info {
   font-weight: 600;
   font-size: 1.4rem;
   line-height: 2rem;
}
