/* CSS */
.button {
   border-radius: 0.25rem;
   padding: 0.5rem;
   --tw-text-opacity: 1;
   color: rgba(255, 255, 255, var(--tw-text-opacity));
   transition: 0.5s;
   background-image: linear-gradient(90deg, #2c69d1 0, #0abcf9 51%, #2c69d1);
   text-align: center;
   border: none;
   transition: 0.5s;
   background-size: 200% auto;
   margin: 10px 5px;
   text-decoration: none;
   min-width: 100px;
}

.button:hover {
   background-position: 100%;
}

.button:disabled {
   background-color: rgba(117, 117, 117, var(--tw-bg-opacity));
   --tw-text-opacity: 1;
   color: rgba(66, 66, 66, var(--tw-text-opacity));
}

.full {
   width: 100%;
   background-size: 200% auto;
}

@media (min-width: 768px) {
}
