.tools {
   display: flex;
   align-items: center;
   justify-content: space-around;
   margin-top: 20px;
}
.total {
   margin-top: 20px;
   font-weight: 600;
   text-align: center;
   font-size: calc(18px + 2vmin);
   border: 1px solid #6085f7;
   border-radius: 24px;
   background-color: chocolate;
   color: #fff;
   padding: 5px 0;
   background-image: url(../../assets/bnb.png);
   background-position: right;
   background-repeat: no-repeat;
   background-size: contain;
}
