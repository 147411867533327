.wrap {
    text-align: center;
    background-color: #000;
    color: rgb(220, 220, 220);
    margin: 5vh 0;
}

.headline {
    font-weight: normal;
    letter-spacing: .1rem;
    text-transform: uppercase;
    margin: 0.5rem 0;
    font-weight: 900;
}
.wrap video{
    width: 30vmin;
    border-radius: 6%;
}
.wrap ul {
    padding: 0;
    margin: 0;
}

.wrap li {
    display: inline-block;
    font-size: 1.5em;
    list-style-type: none;
    padding: 1em;
    text-transform: uppercase;
}

.wrap li span {
    display: block;
    font-size: 4.5rem;
}

@media all and (max-width: 768px) {
    .headline {
        font-size: calc(1.5rem * var(--smaller));
    }

    .wrap li {
        font-size: calc(1.125rem * var(--smaller));
    }

    .wrap li span {
        font-size: calc(3.375rem * var(--smaller));
    }
}